import { navigate } from "../services/navigation";
import intl from "../services/intl";

// Redirects to new location and replaces last item in browser history
// Also checks for external vs internal redirection
export function redirectReplaceHistory(input = "/") {
  const redirectLocation = intl.localizePath(input);
  if (redirectLocation.startsWith("https")) {
    window.location.replace(redirectLocation);
  } else {
    window.history.replaceState({}, "Ritual", redirectLocation);
    navigate(redirectLocation);
  }
}
