import React from "react";
import styled from "styled-components";

// Utils
import { Color, responsive } from "../../utils/style";

// Components
import Container from "../Container";

// Styled Elements
const AuthContainer = styled(Container).attrs({
  className: "col-12 col-sm-6 offset-sm-3 ",
})`
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  margin: 40px auto;
  padding: 24px 24px;

  ${responsive.sm`
    padding: 24px 60px;
  `}

  ${responsive.md`
    margin: 56px auto;
    padding: 56px 80px;
  `}

  ${responsive.lg`
    padding: 56px 100px;
  `}

`;

export default class AuthenticateContainer extends React.Component {
  render() {
    const { children } = this.props;
    return <AuthContainer>{children}</AuthContainer>;
  }
}
